function calc_verzekering(){
    var val = $('.product_info input[name="verzekering"]:checked').val();
    var total = $('#totalprice').attr('data-price');
    var tax = parseFloat($('.product.woocommerce').attr('data-tax'));

    if(val === 'no') {
      if($('.product_info input[name="ophalen"]:checked').val() === 'no'){
        $('.totals_static').hide();
      }

      $('#totalprice').html('&euro;'+ parseFloat(Math.round(total * 100) / 100).toFixed(2));
      $('.totals_static #insurance').hide();
      $('input[name="insurance"]').val(0);

    } else {
      $('.totals_static').show();
      $('.totals_static #insurance').show();

      var shipment_value = parseFloat($('.product_info input[name="shipment_value"]').val().replace(/,/g, '.'));

      if(isNaN(shipment_value) == true) {
        shipment_value = 0;
      }
      
      if(shipment_value > 250000) {
        var insurance_price = (((250000 / 100) * 2) + 12.5) * tax;
      }else if(shipment_value > 2500) {
        var insurance_price = (((shipment_value / 100) * 2) + 12.5) * tax;
      } else {
        var insurance_price = (50 + 12.5) * tax;
      }

      $('.totals_static span.insurance').html('&euro;'+ parseFloat(Math.round(insurance_price * 100) / 100).toFixed(2));
      $('#totalprice').html('&euro;'+  parseFloat(Math.round((insurance_price + parseFloat(total)) * 100) / 100).toFixed(2));
      $('input[name="insurance"]').val((insurance_price / (tax * 100)) * 100 );
    }
  }

  function calc_shipment() {
      var val = $('.product_info input[name="ophalen"]:checked').val();
      var ship_price = parseFloat($('#totalprice').attr('data-pickupprice'));
      var ship_total = parseFloat($('#totalprice').text().replace("€", ""));
      var pickup_data = $('.product.woocommerce').attr('data-pickup');
      var tax = parseFloat($('.product.woocommerce').attr('data-tax'));

      if(isNaN(ship_total)) {
        var ship_total = 0;
      }

      if(val === 'no') {
        $('#shipping_method_0_local_pickup2').prop( "checked", true);
        $('#shipping_method_0_flat_rate1').prop( "checked", false);

        if($('.product_info input[name="verzekering"]:checked').val() === 'no'){
          $('.totals_static').hide();
        }

        if($('#totalprice').attr('data-pickup') === 'true') {
          $('#totalprice').attr('data-pickupprice', 0);
          $('.totals_static #pickup').hide();
          $('#totalprice').html('&euro;'+ parseFloat(Math.round((ship_total - ship_price)* 100) / 100).toFixed(2));
          $('#totalprice').attr('data-pickup', 'false');
        }

         $('input[name="pickup"]').val('');
  
    } else {
        $('#shipping_method_0_local_pickup2').prop( "checked", false);
        $('#shipping_method_0_flat_rate1').prop( "checked", true);

        var place = $('input#place_id').val();
        var c_items = $('.product_info .more_items .item').length;
        var i = 1;

        var packs = [];

        $('.product_info .item').each(function(){
          var i = $(this).attr('data-item');
          var data = {};
          data.count = $('.product_info .item[data-item="'+ i +'"] input#count').val();
          data.width = $('.product_info .item[data-item="'+ i +'"] input#width').val();
          data.height = $('.product_info .item[data-item="'+ i +'"] input#height').val();
          data.length = $('.product_info .item[data-item="'+ i +'"] input#length').val();
          data.weight = $('.product_info .item[data-item="'+ i +'"] input#weight').val();

          packs.push(data);
          i++;
        });

        var place = $('input#place_id').val();
        var zipcode = $('input[name="billing_postcode"]').val();
        var url = $('.product').attr('data-url');
        var type = $('.product').attr('data-type');
        var row = $('.line.small .price.pickup');

        if(place.length > 0 && parseInt(packs[0].length) > 0 && parseInt(packs[0].width) > 0 && parseInt(packs[0].height) > 0 || parseInt(packs[0].weight) > 0 && place.length > 0) {
          if(pickup_data == 'nozip' && zipcode.length > 0) {
            row.text(row.attr('data-noprice'));
            $('input[name="pickup"]').val('request');

          } else if(pickup_data == 'nozip') {
            row.text(row.attr('data-nozip'));
            $('input[name="pickup"]').val('request');

          } else if(pickup_data !== 'false') {
            $('#totalprice').attr('data-pickupprice', pickup_data);
            ship_price = parseFloat(pickup_data);
            ship_price_ex = parseFloat(Math.round(((ship_price / (tax * 100)) * 100 ) * 100) / 100).toFixed(2);

            //row.html('&euro;' + parseFloat(Math.round(ship_price * 100) / 100).toFixed(2));
            row.html('&euro;' + ship_price_ex);
            $('input[name="pickup"]').val(ship_price_ex);
            $('#totalprice').html('&euro;'+ parseFloat(Math.round((ship_total + ship_price) * 100) / 100).toFixed(2));
            $('#vat').html('&euro;' + (parseFloat(vat) + (ship_price - ship_price_ex) ).toFixed(2));
            console.log(ship_price - ship_price_ex);
          } else {
            row.text(row.attr('data-noprice'));
             $('input[name="pickup"]').val('request');
          }

          $('.totals_static').show();
          $('#totalprice').attr('data-pickup', 'true');
          $('.totals_static #pickup').show();
        } else {
          $('.totals_static').show();
          $('#totalprice').attr('data-pickup', 'true');
          $('.totals_static #pickup').show();

          $('input[name="pickup"]').val('request');

          $('#totalprice').attr('data-pickupprice', 0);
          row.text(row.attr('data-nopackage'));
        }
    }
  }

  //// call on subpage ////
  var delay = (function(){
    var timer = 0;
    return function(callback, ms){
      clearTimeout (timer);
      timer = setTimeout(callback, ms);
    };
  })();

  function getDateOnSubpage() {
    delay(function(){

      var place = $('input#place_id').val();
      var c_items = $('.product_info .more_items .item').length;
      var i = 1;

      var packs = [];

      $('.product_info .item').each(function(){
        var i = $(this).attr('data-item');
        var data = {};
        data.count = $('.product_info .item[data-item="'+ i +'"] input#count').val();
        data.width = $('.product_info .item[data-item="'+ i +'"] input#width').val();
        data.height = $('.product_info .item[data-item="'+ i +'"] input#height').val();
        data.length = $('.product_info .item[data-item="'+ i +'"] input#length').val();
        data.weight = $('.product_info .item[data-item="'+ i +'"] input#weight').val();

        packs.push(data);
        i++;
      });

      var place = $('input#place_id').val();
      var zipcode = $('input[name="billing_postcode"]').val();
      var url = $('.product').attr('data-url');
      var type = $('.product').attr('data-type');

      if(place.length > 0 && parseInt(packs[0].length) > 0 && parseInt(packs[0].width) > 0 && parseInt(packs[0].height) > 0 || parseInt(packs[0].weight) > 0 && place.length > 0) {
        $('.totals').show();
        $('.custom_loader').show();
        $('.noResult').hide();
        $('.highVol').hide();

        $('.field.seaport').each(function(){
          $(this).hide();
        });

        $('.field.airport').each(function(){
          $(this).hide();
        });

        $('.field.courier').each(function(){
          $(this).hide();
        });

        $('.product_info .totals .line').each(function(){
          $(this).hide();
        });

        $('input[name="deliver_location"]').val('');
        $('input[name="distance_to_location"]').val('');
        $('input[name="total_price"]').val('');
        $('input[name="insurance"]').val('');

        $.ajax({
            type: "POST",
            url: url + '/ajax/call.php',
            data: {
              placeID: place,
              length: length,
              packs: packs,
              type: type,
              zipcode: zipcode
            },                
            success: function(val){
              console.log(val);
              if(val !== 'false') {
                var data = jQuery.parseJSON(val);
                var html = '';
                var tax = 0;

                $.each(data, function(k, v) {
                  if(v !== 'false' && v !== 'quotation'){

                      if(v.type == 'info') {
                    
                        if(typeof v.region.length !== 'undefined'){

                          $('input[name="region"]').val(v.region);

                          if(v.region === 'Europe' || v.region === 'europe') {
                            tax = 1.21;

                            if(v.country == 'Switzerland') {
                              tax = 1;
                            } else if(v.country == 'Norway') {
                              tax = 1;
                            }

                            $('.product.woocommerce').attr('data-tax', tax);

                          } else {
                            $('.product.woocommerce').attr('data-tax', '1');
                            tax = 1;
                          }
                        }

                        if(typeof v.courier_true !== 'undefined'){
                          if(v.courier_true == 'false') {
                            $('.modal-koerier-big').show();
                          } else {
                            $('.modal-koerier-big').hide();
                          }
                        }

                        if(!(v.pickup == 'false' || v.pickup == 'nozip')) {
                          if(v.pickup.price.length > 0) {
                            $('.product.woocommerce').attr('data-pickup', (v.pickup.price * tax));
                            $('#totalprice').attr('data-pickupprice',(v.pickup.price * tax));
                          } else {
                            $('.product.woocommerce').attr('data-pickup', 'false');
                          }

                        } else {

                          if($('input[name="billing_postcode"]').val() == 0 || v.pickup == 'nozip') {
                            $('.product.woocommerce').attr('data-pickup', 'nozip');
                          } else {
                            $('.product.woocommerce').attr('data-pickup', 'false');
                          }
                        }
                      }

                      if(v.type === 'airfreight') {
                        total_ex = parseFloat(Math.round((v.total_price) * 100) / 100).toFixed(2);
                        total = parseFloat(Math.round((v.total_price * tax) * 100) / 100).toFixed(2);
                        lprice = parseFloat(Math.round((v.price) * 100) / 100).toFixed(2);
                        ltprice = parseFloat(Math.round((v.price * v.volume) * 100) / 100).toFixed(2);
                        vat = parseFloat(Math.round((total - total_ex) * 100) / 100).toFixed(2);
                        price_full = parseFloat(Math.round((v.price_full) * 100) / 100).toFixed(2);
                        discount = v.discount;
                       
                        $('.line.vat').show();
                        $('#vat').html('&euro;' + vat);

                        $('.custom_loader').hide();
                        $('.airport span.distance').text(v.airport.distance + 'Km').parent().show();
                        $('#totalprice').html('€' + total).attr('data-price', total).parent().show();
                        $('#volumeprice').html('(€' + lprice + " per Kg):" ).parent().show();
                        $('#startcost').html('€' + v.start).parent().show();
                        $('#price').html('€' + ltprice).parent().show();
                        $('#minprice').html('Min. prijs €' + v.min_volume).parent().show();
                        $('#airport').html(v.airport.city + ' (' + v.airport.iata +')').parent().show();

                        $('input[name="deliver_location"]').val(v.airport.city + ' (' + v.airport.iata +')');
                        $('input[name="distance_to_location"]').val(v.airport.distance + 'Km');
                        $('input[name="total_price"]').val(total_ex);

                        if(discount > 0) {
                          $('.line.small.discount_wrap').show();
                          $('#discount_pro').html(discount + '%');
                          $('#old_price').html('€' + price_full);
                        }

                        $('input[name="discount"]').val(discount);
                      }

                      if(v.type === 'seafreight') {
                        total_ex = parseFloat(Math.round((v.price) * 100) / 100).toFixed(2);
                        total = parseFloat(Math.round((v.price * tax) * 100) / 100).toFixed(2);
                        vat = parseFloat(Math.round((total - total_ex) * 100) / 100).toFixed(2);
                        price_full = parseFloat(Math.round((v.price_full) * 100) / 100).toFixed(2);
                        discount = v.discount;

                        $('.line.vat').show();
                        $('#vat').html('&euro;' + vat);

                        $('.custom_loader').hide();
                        $('.seaport span.distance').text(v.seaport.distance + 'Km').parent().show();
                        $('#totalprice').html('€' + total).attr('data-price', total).parent().show();

                        $('#seaprice').html('€' + total_ex).parent().show();
                        $('#seaport').html(v.seaport.city + ' (' + v.seaport.pcode +')').parent().show();

                        $('input[name="deliver_location"]').val(v.seaport.city + ' (' + v.seaport.pcode +')');
                        $('input[name="distance_to_location"]').val(v.seaport.distance + 'Km');
                        $('input[name="total_price"]').val(total_ex);

                        if(discount > 0) {
                          $('.line.small.discount_wrap').show();
                          $('#discount_pro').html(discount + '%');
                          $('#old_price').html('€' + price_full);
                        }

                        $('input[name="discount"]').val(discount);
                      }

                      if(v.type === 'courier') {
                        total_ex = parseFloat(Math.round((v.price) * 100) / 100).toFixed(2);
                        total = parseFloat(Math.round((v.price * tax) * 100) / 100).toFixed(2);
                        vat = parseFloat(Math.round((total - total_ex) * 100) / 100).toFixed(2);
                        price_full = parseFloat(Math.round((v.price_full) * 100) / 100).toFixed(2);
                        discount = v.discount;
                        
                        $('.line.vat').show();
                        $('#vat').html('&euro;' + vat);

                        $('.custom_loader').hide();
                        $('#totalprice').html('€' + total).attr('data-price', total).parent().show();
                        $('.courier .area').html(v.area + ' (Zone ' + v.zone + ')').parent().show();
                        $('#volume').html(v.volume).parent().show();

                        $('#courprice').html('€' + total_ex).parent().show();

                        $('input[name="deliver_location"]').val(v.area + ' (' + v.zone +')');
                        $('input[name="total_price"]').val(total_ex);

                        if(discount > 0) {
                          $('.line.small.discount_wrap').show();
                          $('#discount_pro').html(discount + '%');
                          $('#old_price').html('€' + price_full);
                        }

                        $('input[name="discount"]').val(discount);
                      }

                      if(v.type === 'roadfreight') {
                        total_ex = parseFloat(Math.round((v.price) * 100) / 100).toFixed(2);
                        total = parseFloat(Math.round((v.price * tax) * 100) / 100).toFixed(2);
                        vat = parseFloat(Math.round((total - total_ex) * 100) / 100).toFixed(2);
                        price_full = parseFloat(Math.round((v.price_full) * 100) / 100).toFixed(2);
                        discount = v.discount;
                        
                        $('.line.vat').show();
                        $('#vat').html('&euro;' + vat);
                        
                        $('.custom_loader').hide();
                        $('#totalprice').html('€' + total).attr('data-price', total).parent().show();
                        $('.courier .area').html(v.area).parent().show();
                        $('#volume').html(v.volume).parent().show();

                        $('#roadprice').html('€' + total_ex).parent().show();

                        $('input[name="deliver_location"]').val(v.area);
                        $('input[name="total_price"]').val(total_ex);
                        
                        if(discount > 0) {
                          $('.line.small.discount_wrap').show();
                          $('#discount_pro').html(discount + '%');
                          $('#old_price').html('€' + price_full);
                        }

                        $('input[name="discount"]').val(discount);
                      }

                      if(v.type !== 'info'){
                        calc_verzekering();
                        calc_shipment();
                      }

                  } else if(v === 'quotation') {
                      $('.custom_loader').hide();
                      $('.highVol').velocity('fadeIn');      
                  } else {
                      $('.custom_loader').hide();
                      $('.noResult').velocity('fadeIn');                          
                  }
                });
              }

            },
            error: function(e) {
              console.log(e);
            }
        });
      }
    }, 300 );
  } 

  if($('.basic_prod .product_info').length > 0 ) {
    getDateOnSubpage();
  }

  $(document).on('change', '.basic_prod .product_info input.calc', function(){
    getDateOnSubpage();
  });

  $('.basic_prod .product_info input[name="ophalen"]').change(function(){
    calc_shipment();
  });

  $('.basic_prod input[name="billing_postcode"]').change(function(){
    getDateOnSubpage();
  });

  $('.basic_prod .product_info input[name="verzekering"]').change(function(){
    calc_verzekering();
    calc_shipment();
  });

  $('.basic_prod .product_info input[name="shipment_value"]').change(function(){
    calc_verzekering();
    calc_shipment();
  });

  google.maps.event.addListener(autocomplete, 'place_changed', function() {
    var place = autocomplete.getPlace();
    if (place.place_id) {
      var place_id = place.place_id;
      $('input#place_id').val(place_id);

      if ($('.product_info.ship').length > 0) {
        getDateOnSubpage();
      }

      if($('.product.lostfound').length > 0) {
        item_change();
      }
    }
  });

  //// add package

  /// add package
  $('.sub_add_package').click(function(){
    var cur_items = $('.product_info .more_items').attr('data-items');
    var new_item = parseInt(cur_items) + 1;

    var html = '';
    html += '<div class="item" data-item="'+ new_item +'">';
    html += '<div class="field count_wrap">';
    html += '<input type="number" name="count_'+ new_item +'" min="0" id="count" class="small calc" required value="1">';
    html += '</div>';

    html += '<div class="field size_wrap">';
    html += '<input type="number" min="0" name="length_'+ new_item +'" id="length" placeholder="cm*" class="small calc length" required>';
    html += '<input type="number" min="0" name="width_'+ new_item +'" id="width" placeholder="cm*" class="small calc width" required>';
    html += '<input type="number" min="0" name="height_'+ new_item +'" id="height" placeholder="cm*" class="small calc last height" required>';
    html += '</div>';

    html += '<div class="field weight_wrap">';
    html += '<input type="number" name="weight_'+ new_item +'" min="0" id="weight" class="small calc" placeholder="kg*" required>';
    html += '</div>';

    html += '<i class="fa fa-times" data-item="'+ new_item +'"></i>';
    html += '</div>';

    $('.product_info .more_items').attr('data-items', new_item);
    $('.product_info .more_items').append(html);

    $('input[name="items"]').val($('.product_info .more_items .item').length);
  });

  $(document).on('click', '.basic_prod .product_info .more_items .fa-times', function() {
    var item = $(this).attr('data-item');
    $('.product_info .more_items .item[data-item="' + item +'"]').remove();
    $('input[name="items"]').val($('.product_info .more_items .item').length);
    getDateOnSubpage();
  });